/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const {Columns, Card} = Object.assign({}, _provideComponents(), props.components);
  if (!Card) _missingMdxReference("Card", true);
  if (!Columns) _missingMdxReference("Columns", true);
  return React.createElement(Columns, null, React.createElement(Card, {
    title: "黑龙江神顶峰黑蜂产品公司：生态酿蜜广受赞誉 带动蜂业发展",
    content: "【黑龙江旅游食品网】（高伟 李钊仪）金秋十月，硕果累累。１０月１８～２２日，第八届黑龙江省绿色食品产业博览会和第三届中国·黑龙江国际大米节在哈尔滨国际会展中心举办。展会上，黑龙江神顶峰黑蜂产品甜蜜亮相，深受消费者追捧。黑龙江神顶峰黑蜂产品有限公司董事长徐美臻做客《中国食品报》直播间进行专访...",
    img: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/shengtainiangmi.jpg",
    link: "https://www.163.com/dy/article/FPI049N10534HYZM.html"
  }), React.createElement(Card, {
    title: "献给最美逆行者的甜蜜|黑龙江神顶峰为鸡西援鄂医疗捐赠72箱雪蜜",
    content: "【香港商报黑龙江新闻3月28日电】记者 王琳 张晓磊 战“疫”一线苦，赠“蜜”心里甜。3月26日，在黑龙江省鸡西市工商联、卫健委、慈善总会等领导的见证和支持下，黑龙江省神顶峰黑蜂产品公司由专车直达指定地点，为鸡西市援鄂医疗队20名凯旋归来的白衣战士们及指挥部防疫工作人员捐赠神顶峰雪蜜72箱，送上养蜂人的甜蜜、温暖和祝福...",
    img: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/yiliaodui.jpg",
    link: "https://www.163.com/dy/article/F8R8V99A0530U6BC.html"
  }), React.createElement(Card, {
    title: "蜂蜜做抵押撬动1000万 “神顶峰”黑蜂公司获得金融强助力",
    content: "黑龙江日报8月18日讯 神顶峰是民营小企业，主推全封盖成熟蜜生产。经过20年发展，企业面临蜂业产业基地建设等众多问题，只有解决了资金问题，才能实现这些“美好愿景”...",
    img: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/jinrongzhuli.jpg",
    link: "https://heilongjiang.dbw.cn/system/2020/08/18/058481165.shtml"
  }), React.createElement(Card, {
    title: "神顶峰黑蜂产品哈洽会上甜蜜飘香 引领养生新风尚",
    content: "东北网6月19日讯(记者 霍枭涵)第二十九届哈哈洽会上，黑龙江神顶峰黑蜂产品甜蜜亮相，深受养生人士追捧。神顶峰是完达山脉最高峰，大半年的积雪覆盖，冻结了一切细菌与污染，为神顶峰黑蜂产品提供了最天然、无污染的蜜源...",
    img: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/00300632611_313871ae.jpg",
    link: "https://heilongjiang.dbw.cn/system/2018/06/19/058014663.shtml"
  }), React.createElement(Card, {
    title: "黑蜂哥二十二年匠心 只为一罐好蜜｜封面故事",
    content: "神顶峰——完达山脉最高峰，海拔831米。当太阳从东方升起，顷刻间，大地披上霞光，万物苏醒。山间的每一片树叶都奋力向着阳光伸展，每一朵花都急不可耐张开花瓣接受光的沐浴。各种声音也开始苏醒、交织……",
    img: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/heifengge1.png",
    link: "https://mp.weixin.qq.com/s/kTd8AeBvcli2yx7A1Tcv1g"
  }), React.createElement(Card, {
    title: "新农业100人 | 战立新：中国最好的蜂蜜，一定也是世界最好的",
    content: "走出原本工作稳定的“舒适圈”创业，这样的例子在新农人中并不少见。但每个人的故事都有着自己的时间轴和不同。头戴大皮帽、身着皮袄，手捧产自“中国雪乡”黑龙江神顶峰的椴树黑蜂雪蜜，实在又真诚，这便是“黑蜂哥”带给众多网友最为生动直观的印象。",
    img: "https://sdfbee-resource-1303933394.cos.ap-beijing.myqcloud.com/heifengge2.png",
    link: "https://mp.weixin.qq.com/s/3u0laMh_UYZsk2OLFqSZ1Q"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
